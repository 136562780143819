// TODO: Automate import using Glyphs enum?
import { ReactComponent as AddToCartIcon } from "assets/images/icons/add-to-cart.svg";
import { ReactComponent as AlignCenterIcon } from "assets/images/icons/align-center.svg";
import { ReactComponent as AlignJustifyIcon } from "assets/images/icons/align-justify.svg";
import { ReactComponent as AlignLeftIcon } from "assets/images/icons/align-left.svg";
import { ReactComponent as AlignRightIcon } from "assets/images/icons/align-right.svg";
import { ReactComponent as AngleLeftIcon } from "assets/images/icons/angle-left.svg";
import { ReactComponent as AngleRightIcon } from "assets/images/icons/angle-right.svg";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/icons/arrow-up.svg";
import { ReactComponent as ArrowsIcon } from "assets/images/icons/arrows.svg";
import { ReactComponent as BanIcon } from "assets/images/icons/ban.svg";
import { ReactComponent as BarsIcon } from "assets/images/icons/bars.svg";
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import { ReactComponent as CaretDownIcon } from "assets/images/icons/caret-down.svg";
import { ReactComponent as CartIcon } from "assets/images/icons/cart.svg";
import { ReactComponent as CheckCircleIcon } from "assets/images/icons/check-circle.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy.svg";
import { ReactComponent as DomainIcon } from "assets/images/icons/domain.svg";
import { ReactComponent as EnvelopeIcon } from "assets/images/icons/envelope.svg";
import { ReactComponent as ExclamationCircleIcon } from "assets/images/icons/exclamation-circle.svg";
import { ReactComponent as ExitIcon } from "assets/images/icons/exit.svg";
import { ReactComponent as EyeIcon } from "assets/images/icons/eye.svg";
import { ReactComponent as FaqIcon } from "assets/images/icons/faq.svg";
import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import { ReactComponent as ImageEditIcon } from "assets/images/icons/image-edit.svg";
import { ReactComponent as ImagePlusIcon } from "assets/images/icons/image-plus.svg";
import { ReactComponent as InfoCircleIcon } from "assets/images/icons/info-circle.svg";
import { ReactComponent as LockIcon } from "assets/images/icons/lock.svg";
import { ReactComponent as MapIcon } from "assets/images/icons/map.svg";
import { ReactComponent as MinusIcon } from "assets/images/icons/minus.svg";
import { ReactComponent as NegativeCold } from "assets/images/icons/negative-cold.svg";
import { ReactComponent as OrderIcon } from "assets/images/icons/order.svg";
import { ReactComponent as PenIcon } from "assets/images/icons/pen.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icons/phone.svg";
import { ReactComponent as PlusIcon } from "assets/images/icons/plus.svg";
import { ReactComponent as PositiveCold } from "assets/images/icons/positive-cold.svg";
import { ReactComponent as PrintIcon } from "assets/images/icons/print.svg";
import { ReactComponent as ProductIcon } from "assets/images/icons/product.svg";
import { ReactComponent as QuestionCircleIcon } from "assets/images/icons/question-circle.svg";
import { ReactComponent as RedoIcon } from "assets/images/icons/redo.svg";
import { ReactComponent as SaveIcon } from "assets/images/icons/save.svg";
import { ReactComponent as ScissorsIcon } from "assets/images/icons/scissors.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { ReactComponent as SettingIcon } from "assets/images/icons/setting.svg";
import { ReactComponent as StarIcon } from "assets/images/icons/star.svg";
import { ReactComponent as StoreIcon } from "assets/images/icons/store.svg";
import { ReactComponent as StripeIcon } from "assets/images/icons/stripe.svg";
import { ReactComponent as TimesIcon } from "assets/images/icons/times.svg";
import { ReactComponent as TrashIcon } from "assets/images/icons/trash.svg";
import { ReactComponent as UsersIcon } from "assets/images/icons/users.svg";
import { ReactComponent as WebIcon } from "assets/images/icons/web.svg";
import { FunctionComponent, SVGProps } from "react";

export enum Glyphs {
  addToCart = "add-to-cart",
  angleLeft = "angle-left",
  angleRight = "angle-right",
  alignCenter = "align-center",
  alignJustify = "align-justify",
  alignLeft = "align-left",
  alignRight = "align-right",
  arrowLeft = "arrow-left",
  arrowRight = "arrow-right",
  arrowUp = "arrow-up",
  arrowDown = "arrow-down",
  arrows = "arrows",
  bars = "bars",
  ban = "ban",
  calendar = "calendar",
  caretDown = "caret-down",
  cart = "cart",
  check = "check",
  checkCircle = "check-circle",
  copy = "copy",
  domain = "domain",
  envelope = "envelope",
  exclamationCircle = "exclamation-circle",
  exit = "exit",
  eye = "eye",
  faq = "faq",
  home = "home",
  imageEdit = "image-edit",
  imagePlus = "image-plus",
  infoCircle = "info-circle",
  lock = "lock",
  map = "map",
  minus = "minus",
  negativeCold = "negative-cold",
  order = "order",
  pen = "pen",
  phone = "phone",
  plus = "plus",
  positiveCold = "positive-cold",
  print = "print",
  product = "product",
  questionCircle = "question-circle",
  redo = "redo",
  save = "save",
  scissors = "scissors",
  search = "search",
  setting = "setting",
  star = "star",
  store = "store",
  stripe = "stripe",
  times = "times",
  trash = "trash",
  users = "users",
  web = "web",
}

export const GlyphSVG: {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  [Glyphs.addToCart]: AddToCartIcon,
  [Glyphs.angleLeft]: AngleLeftIcon,
  [Glyphs.angleRight]: AngleRightIcon,
  [Glyphs.alignCenter]: AlignCenterIcon,
  [Glyphs.alignJustify]: AlignJustifyIcon,
  [Glyphs.alignLeft]: AlignLeftIcon,
  [Glyphs.alignRight]: AlignRightIcon,
  [Glyphs.arrowLeft]: ArrowLeftIcon,
  [Glyphs.arrowRight]: ArrowRightIcon,
  [Glyphs.arrowDown]: ArrowDownIcon,
  [Glyphs.arrowUp]: ArrowUpIcon,
  [Glyphs.arrows]: ArrowsIcon,
  [Glyphs.bars]: BarsIcon,
  [Glyphs.ban]: BanIcon,
  [Glyphs.calendar]: CalendarIcon,
  [Glyphs.caretDown]: CaretDownIcon,
  [Glyphs.cart]: CartIcon,
  [Glyphs.check]: CheckIcon,
  [Glyphs.checkCircle]: CheckCircleIcon,
  [Glyphs.copy]: CopyIcon,
  [Glyphs.domain]: DomainIcon,
  [Glyphs.envelope]: EnvelopeIcon,
  [Glyphs.exclamationCircle]: ExclamationCircleIcon,
  [Glyphs.exit]: ExitIcon,
  [Glyphs.eye]: EyeIcon,
  [Glyphs.faq]: FaqIcon,
  [Glyphs.home]: HomeIcon,
  [Glyphs.imageEdit]: ImageEditIcon,
  [Glyphs.imagePlus]: ImagePlusIcon,
  [Glyphs.infoCircle]: InfoCircleIcon,
  [Glyphs.lock]: LockIcon,
  [Glyphs.map]: MapIcon,
  [Glyphs.minus]: MinusIcon,
  [Glyphs.negativeCold]: NegativeCold,
  [Glyphs.order]: OrderIcon,
  [Glyphs.pen]: PenIcon,
  [Glyphs.phone]: PhoneIcon,
  [Glyphs.plus]: PlusIcon,
  [Glyphs.positiveCold]: PositiveCold,
  [Glyphs.print]: PrintIcon,
  [Glyphs.product]: ProductIcon,
  [Glyphs.questionCircle]: QuestionCircleIcon,
  [Glyphs.redo]: RedoIcon,
  [Glyphs.save]: SaveIcon,
  [Glyphs.scissors]: ScissorsIcon,
  [Glyphs.search]: SearchIcon,
  [Glyphs.setting]: SettingIcon,
  [Glyphs.store]: StoreIcon,
  [Glyphs.star]: StarIcon,
  [Glyphs.stripe]: StripeIcon,
  [Glyphs.times]: TimesIcon,
  [Glyphs.trash]: TrashIcon,
  [Glyphs.users]: UsersIcon,
  [Glyphs.web]: WebIcon,
};
