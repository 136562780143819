import React from "react";
import { default as ReactSelect } from "react-select";
import withFieldWrapper, { CustomFieldProps } from "../withFieldWrapper";

export interface SelectProps extends CustomFieldProps {
  options?: any;
  disabled?: boolean;
  clearable?: boolean;
  initValue?: (options: any[]) => any;
}

const Select = ({
  id,
  hasError,
  disabled,
  clearable = true,
  field: { name, value, onBlur },
  form: { setFieldValue },
  initValue,
  options = [],
  placeholder,
}: SelectProps) => {
  const onChange = (option: any) => {
    setFieldValue(name, option ? option.value : undefined);
  };

  return (
    <ReactSelect
      className="flex-1"
      inputId={id}
      name={name}
      value={
        initValue
          ? initValue(options)
          : options.find((option: any) => option.value === value) || ("" as any)
      }
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      isSearchable={true}
      onBlur={onBlur}
      isClearable={clearable}
      isDisabled={disabled}
      blurInputOnSelect={false}
      styles={{
        placeholder: provided => ({
          ...provided,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }),
      }}
      theme={theme => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          baseUnit: 5,
        },
        colors: {
          ...theme.colors,
          primary: "#60AE41",
          primary25: "#D7EBD0",
          danger: "#C53030",
          dangerLight: "#FED7D7",
          neutral20: hasError ? "#C53030" : "#e2e8f0",
        },
      })}
    />
  );
};

export default withFieldWrapper(Select);
