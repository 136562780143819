import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { createUploadLink } from "apollo-upload-client";
import config from "config.json";

const uploadLink = createUploadLink({
  uri: config.apiUri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-origin": window.location.host,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  // TODO: remove any
  link: ApolloLink.from([authLink, uploadLink as any]),
  cache: new InMemoryCache(),
});

export default client;
