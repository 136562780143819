import { getImage } from "utils";

export interface Picture {
  id: string;
  original: string;
  large: string;
  medium: string;
  small: string;
  thumbnail: string;
}

export const pictureBuilder = (values: Partial<Picture> = {}): Picture => ({
  id: "",
  original: getImage("placeholder.jpg"),
  large: getImage("placeholder_large.jpg"),
  medium: getImage("placeholder_medium.jpg"),
  small: getImage("placeholder_small.jpg"),
  thumbnail: getImage("placeholder_thumbnail.jpg"),
  ...values,
});

export interface PictureValues {
  id: string;
  original: string;
  large: string;
  medium: string;
  small: string;
  thumbnail: string;
}

export const pictureValuesBuilder = (
  values: Partial<PictureValues> = {}
): PictureValues => ({
  id: "",
  original: "",
  large: "",
  medium: "",
  small: "",
  thumbnail: "",
  ...values,
});
