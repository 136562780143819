import { gql, useLazyQuery, useMutation } from "@apollo/client";
import WebsiteStatusModal from "components/organisms/WebsiteStatusModal";
import Cookies from "js-cookie";
import { WebsiteStatus } from "models/WebsiteConfiguration";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { MyWebsiteStatus, SetAvailWebsiteByClient } from "types";
import { formatWebsiteStatus } from "utils/websiteConfigurations";

interface WebsiteStatusProps {
  isOpen: boolean;
  openWebsiteStatus: () => void;
}

export const WebsiteStatusContext = React.createContext<WebsiteStatusProps>({
  isOpen: false,
  openWebsiteStatus: () => false,
});

const MY_WEBSITE_STATUS_QUERY = gql`
  query MyWebsiteStatus {
    myWebsiteStatus {
      availWebsiteByClient
      mandatoryTasks {
        labels
        routes
        status
      }
      optionalTasks {
        labels
        routes
        status
      }
      mandatoryTasksAreTrue
    }
  }
`;

const SET_AVAIL_WEBSITE_BY_CLIENT = gql`
  mutation SetAvailWebsiteByClient($availWebsiteByClient: Boolean!) {
    setAvailWebsiteByClient(availWebsiteByClient: $availWebsiteByClient) {
      availWebsiteByClient
    }
  }
`;

export const WebsiteStatusProvider: FunctionComponent<Record<
  string,
  unknown
>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [websiteStatus, setWebsiteStatus] = useState<WebsiteStatus>();
  const intro_showed = Cookies.get("intro_showed");

  const [setAvailWebsiteByClient] = useMutation<SetAvailWebsiteByClient>(
    SET_AVAIL_WEBSITE_BY_CLIENT,
    {
      onCompleted({ setAvailWebsiteByClient }) {
        if (websiteStatus) {
          const newWebsiteStatus = { ...websiteStatus };
          newWebsiteStatus.availWebsiteByClient =
            setAvailWebsiteByClient.availWebsiteByClient;
          setWebsiteStatus(newWebsiteStatus);
        }
      },
    }
  );

  const [refreshWebsiteStatus] = useLazyQuery<MyWebsiteStatus>(
    MY_WEBSITE_STATUS_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted({ myWebsiteStatus }) {
        if (myWebsiteStatus) {
          setWebsiteStatus(formatWebsiteStatus(myWebsiteStatus));
          !myWebsiteStatus.mandatoryTasksAreTrue &&
            intro_showed &&
            setIsOpen(true);
        }
      },
    }
  );

  useEffect(() => {
    if (localStorage.getItem("token")) {
      refreshWebsiteStatus();
    }
  }, [refreshWebsiteStatus]);

  const openWebsiteStatus = () => {
    setIsOpen(true);
  };

  return (
    <WebsiteStatusContext.Provider value={{ isOpen, openWebsiteStatus }}>
      {localStorage.getItem("token") && (
        <WebsiteStatusModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          websiteStatus={websiteStatus}
          setAvailWebsiteByClient={setAvailWebsiteByClient}
          refreshWebsiteStatus={refreshWebsiteStatus}
        />
      )}

      {children}
    </WebsiteStatusContext.Provider>
  );
};

export const useWebsiteStatusContext: () => WebsiteStatusProps = () => {
  const context = useContext(WebsiteStatusContext);
  if (context === undefined) {
    throw new Error(
      `useWebsiteStatusContext must be used within an CookieConsentProvider`
    );
  }
  return context;
};
