import React, { createContext, useContext } from "react";
import { useAuthContext } from "./AuthContext";

export interface User {
  name: string;
  phone: string;
  image: string;
  isProducer: boolean;
  isPickupPoint: boolean;
  isStaff: boolean;
  partnerName: string;
}

export enum userCategory {
  PRODUCER = "PRODUCER",
  PICKUP_POINT = "PICKUP_POINT",
}

interface UserContextProps {
  isAuthenticated: boolean;
  user: User;
}

const UserContext = createContext<UserContextProps>({
  isAuthenticated: false,
  user: {
    name: "",
    phone: "",
    image: "",
    isPickupPoint: false,
    isProducer: false,
    isStaff: false,
    partnerName: "",
  },
});

const UserProvider = (props: any) => {
  return (
    <UserContext.Provider
      value={{
        isAuthenticated: !!useAuthContext().currentUser,
        user: useAuthContext().currentUser,
      }}
      {...props}
    />
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUserContext };
