import React, { createContext, Dispatch, useContext, useState } from "react";

interface SideBarContextProps {
  isSidebarOpen: boolean;
  setSidebarOpen: Dispatch<React.SetStateAction<boolean>>;
}

const SideBarContext = createContext<SideBarContextProps>({
  isSidebarOpen: true,
  setSidebarOpen: () => undefined,
});

const SideBarProvider = (props: any) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  return (
    <SideBarContext.Provider
      value={{ isSidebarOpen, setSidebarOpen }}
      {...props}
    />
  );
};

const useSidebarContext = () => {
  const context = useContext(SideBarContext);
  if (context === undefined) {
    throw new Error(`sideBar must be used within a SideBarProvider`);
  }
  return context;
};

export { SideBarProvider, useSidebarContext };
