import { format as formatDate, formatRelative } from "date-fns";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import translationFR from "locales/fr";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";
import { customFrLocale, customFrLocaleNoTime } from "./customFrLocale";

// register fr locale for numeral formatting (eg. price)
numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});

const numeralLocales: { [key: string]: string } = {
  fr: "fr",
};

const dateFnsLocales: { [key: string]: Locale | undefined } = {
  fr: customFrLocale,
};

const dateFnsLocalesNoTime: { [key: string]: Locale | undefined } = {
  fr: customFrLocaleNoTime,
};

const localeDateFormats: { [key: string]: string } = {
  fr: "dd/MM/yyyy",
};

const localeDayWithDate: { [key: string]: string } = {
  fr: "eee dd/MM",
};

const formatTranslation = (value: any, format?: string, lng?: string) => {
  if (!value) return value;
  switch (format) {
    case "price": {
      lng && numeral.locale(numeralLocales[lng]);
      return numeral(value).format("0,0.00");
    }
    case "date": {
      const date = value instanceof Date ? value : new Date(value);
      return formatDate(date, !!lng ? localeDateFormats[lng] : "yyyy-MM-dd");
    }
    case "dayWithDate": {
      const date = value instanceof Date ? value : new Date(value);
      const dateOptions = {
        locale: !!lng ? dateFnsLocales[lng] : undefined,
      };
      return formatDate(
        date,
        !!lng ? localeDayWithDate[lng] : "eee MM-dd",
        dateOptions
      );
    }
    case "dateRelative": {
      const dateValue =
        Object.prototype.toString.call(value) === "[object Date]"
          ? value
          : new Date(value);
      const dateOptions = {
        locale: !!lng ? dateFnsLocales[lng] : undefined,
        // withoutTime: false,
      };
      return formatRelative(dateValue, new Date(), dateOptions);
    }
    case "dateRelativeNoTime": {
      const dateValue =
        Object.prototype.toString.call(value) === "[object Date]"
          ? value
          : new Date(value);

      const dateOptions = {
        locale: !!lng ? dateFnsLocalesNoTime[lng] : undefined,
        // withoutTime: true,
      };

      return formatRelative(dateValue, new Date(), dateOptions);
    }
    case "minToHHMM": {
      var h = value / 60;
      var rh = Math.floor(h);
      var m = (h - rh) * 60;
      var rm = Math.round(m);

      return `${rh}h${rm ? String(rm).padStart(2, "0") : ""}`;
    }
    case "phone": {
      return parsePhoneNumberFromString(value, "FR")?.formatNational();
    }
    default: {
      return value;
    }
  }
};

// the translations
const resources = {
  fr: {
    translation: translationFR,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "fr",
    whitelist: ["fr"],
    resources,
    interpolation: {
      escapeValue: false, // escaping not needed for react
      format: formatTranslation,
    },
  });

export default i18n;
