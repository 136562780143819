import classNames from "classnames";
import React from "react";
import withFieldWrapper, { CustomFieldProps } from "../withFieldWrapper";

interface TextAreaProps extends CustomFieldProps {
  maxLength?: number;
  rows?: number;
}

const TextArea = ({
  id,
  maxLength,
  hasError,
  placeholder,
  isReadOnly,
  rows,
  field: { value, name, ...fieldRest },
}: TextAreaProps) => (
  <div className="relative w-full">
    {maxLength && value && (
      <span
        className={classNames(
          "absolute top-0 right-0 text-sm tracking-wider font-semibold -mt-6",
          {}
        )}
      >{`${value.length}/${maxLength}`}</span>
    )}
    <textarea
      id={id}
      name={name}
      value={value || ""}
      rows={rows}
      maxLength={maxLength}
      placeholder={placeholder}
      readOnly={isReadOnly}
      className={classNames("form-input w-full", {
        "border-red-500": hasError,
        "shadow-none bg-gray-300": isReadOnly,
      })}
      {...fieldRest}
    />
  </div>
);

export default withFieldWrapper(TextArea);
