import { IntroCarouselProvider } from "contexts/IntroCarousel";
import { useUserContext } from "contexts/UserContext";
import { WebsiteStatusProvider } from "contexts/WebsiteStatusContext";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import ScrollToTop from "utils/index";

const AppAuthenticated = React.lazy(() => import("./AppAuthenticated"));
const AppUnauthenticated = React.lazy(() => import("./AppUnauthenticated"));

const App: React.FC = () => {
  const { isAuthenticated } = useUserContext();

  return (
    <Suspense fallback={"Loading..."}>
      <Router>
        <ScrollToTop />
        <QueryParamProvider ReactRouterRoute={Route}>
          <WebsiteStatusProvider>
            <IntroCarouselProvider>
              {isAuthenticated ? <AppAuthenticated /> : <AppUnauthenticated />}
            </IntroCarouselProvider>
          </WebsiteStatusProvider>
        </QueryParamProvider>
      </Router>
    </Suspense>
  );
};

export default App;
