import classNames from "classnames";
import { Glyphs } from "constants/Glyphs";
import { LocationDescriptor } from "history";
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  HTMLProps,
  ReactNode,
  Ref,
} from "react";
import { Link, LinkProps } from "react-router-dom";
import Icon from "../Icon";

export interface ButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  btnSize?: ButtonSize;
  withGlyph?: Glyphs;
  glyphPosition?: GlyphPosition;
  glyphClassName?: string;
  btnVariant?: ButtonVariant;
  btnStyle?: ButtonStyle;
  btnState?: ButtonState;
  href?: string | LocationDescriptor;
  isExternal?: boolean;
  renderLeading?: () => ReactNode;
  renderTrailing?: () => ReactNode;
}

export enum GlyphPosition {
  left = "left",
  right = "right",
}

export enum ButtonSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export enum ButtonVariant {
  default = "default",
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark",
  link = "link",
}

export enum ButtonStyle {
  contained = "contained",
  outlined = "outlined",
  text = "text",
}

export enum ButtonState {
  flat = "flat",
  raised = "raised",
  raisedXl = "raised-xl",
}

interface LinkAttr {
  href?: string;
  target?: string;
  rel?: string;
  to?: string | LocationDescriptor;
}

export type BtnType =
  | DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  | HTMLProps<HTMLAnchorElement>
  | LinkProps;

const Button = (
  {
    btnSize = ButtonSize.medium,
    className,
    withGlyph,
    glyphPosition = GlyphPosition.left,
    glyphClassName,
    btnVariant = ButtonVariant.default,
    btnStyle = ButtonStyle.text,
    btnState = ButtonState.flat,
    href,
    isExternal,
    children,
    renderLeading,
    renderTrailing,
    ...props
  }: ButtonProps & BtnType,
  ref: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement>
) => {
  const Markup = href ? (isExternal ? "a" : Link) : "button";
  const linkElementAttr: LinkAttr = {};

  if (isExternal) {
    linkElementAttr.href = href as string;
    linkElementAttr.target = "_blank";
    linkElementAttr.rel = "noopener noreferrer";
  } else {
    linkElementAttr.to = href;
  }

  return (
    <Markup
      {...(props as any)}
      {...(linkElementAttr as any)}
      ref={ref}
      className={classNames(
        className,
        " btn",
        {
          "btn--with-children group": children,
          "btn-icon": withGlyph && !children,
        },
        {
          "btn--lg": btnSize === ButtonSize.large,
          "btn--sm": btnSize === ButtonSize.small,
        },
        {
          "btn--with-glyph btn__glyph--left":
            withGlyph && children && glyphPosition === GlyphPosition.left,
          "btn--with-glyph btn__glyph--right":
            withGlyph && children && glyphPosition === GlyphPosition.right,
        },
        {
          "btn--primary": btnVariant === ButtonVariant.primary,
          "btn--secondary": btnVariant === ButtonVariant.secondary,
          "btn--success": btnVariant === ButtonVariant.success,
          "btn--danger": btnVariant === ButtonVariant.danger,
          "btn--warning": btnVariant === ButtonVariant.warning,
          "btn--info": btnVariant === ButtonVariant.info,
          "btn--light": btnVariant === ButtonVariant.light,
          "btn--dark": btnVariant === ButtonVariant.dark,
          "btn--link": btnVariant === ButtonVariant.link,
        },
        {
          "btn--contained": btnStyle === ButtonStyle.contained,
          "btn--outline": btnStyle === ButtonStyle.outlined,
          "btn--text bg-transparent": btnStyle === ButtonStyle.text,
        },
        {
          "btn--raised": btnState === ButtonState.raised,
          "btn--raised-xl": btnState === ButtonState.raisedXl,
        }
      )}
    >
      {renderLeading && renderLeading()}
      {glyphPosition === GlyphPosition.right && children}
      {withGlyph && (
        <Icon
          className={classNames("btn__glyph", glyphClassName)}
          glyph={withGlyph}
        />
      )}
      {glyphPosition === GlyphPosition.left && children}
      {renderTrailing && renderTrailing()}
    </Markup>
  );
};

export default forwardRef(Button);
