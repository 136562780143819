import { Picture, PictureValues } from "models/Picture";

export const formatPicture = (result: any): Picture => ({
  id: result.id,
  original: result.original,
  large: result.large,
  medium: result.medium,
  small: result.small,
  thumbnail: result.thumbnail,
});

export const formatPictureValues = (picture: Picture): PictureValues => ({
  id: picture.id,
  original: picture.original,
  large: picture.large,
  medium: picture.medium,
  small: picture.small,
  thumbnail: picture.thumbnail,
});
