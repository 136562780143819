import classNames from "classnames";
import Icon from "components/atoms/Icon";
import Label from "components/atoms/Label";
import Tooltip from "components/atoms/Tooltip";
import { Glyphs } from "constants/Glyphs";
import {
  FieldInputProps,
  FieldProps,
  FormikErrors,
  FormikProps,
  getIn,
} from "formik";
import React, { ComponentType, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface CustomFieldProps<V = any> {
  id: string;
  placeholder?: string;
  hasError?:
    | string
    | false
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[];
  className?: string;
  required: boolean;
  isReadOnly: boolean;
  fixedDecimalScale?: boolean;
  field: FieldInputProps<V>;
  form: FormikProps<V>;
  innerRef: React.MutableRefObject<any>;
  onAction?: () => void;
  onFocus?: () => void;
}

interface FieldWrapperProps extends FieldProps {
  label?: string;
  labelInline?: boolean;
  labelReverse?: boolean;
  helperText?: ReactNode;
  placeholder?: string;
  className?: string;
  componentClassName?: string;
  isOptional?: boolean;
  isReadOnly?: boolean;
  isEditableByStaff?: boolean;
  showReadOnlyLabel?: boolean;
  withLabelError?: boolean;
  innerRef: React.MutableRefObject<any>;
}

const withFieldWrapper = (Component: ComponentType<CustomFieldProps>) => ({
  label,
  labelInline,
  labelReverse,
  helperText,
  placeholder,
  className,
  componentClassName,
  isOptional,
  isReadOnly,
  isEditableByStaff,
  showReadOnlyLabel,
  withLabelError = true,
  field,
  form,
  meta,
  innerRef,
  ...rest
}: FieldWrapperProps) => {
  const { t } = useTranslation();
  const hasError =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <div
      className={classNames(
        "flex flex-col",
        {
          "mb-8": !className?.includes("mb"),
        },
        className
      )}
    >
      <div
        className={classNames("flex", {
          "flex-col": !labelInline,
          "flex-row items-center": labelInline,
          "flex-row-reverse justify-end": labelReverse,
        })}
      >
        {label && (
          <Label
            className={classNames("inline-flex items-center", {
              "mb-2": !labelInline && !helperText,
              "mr-2": labelInline && !labelReverse,
              "ml-2": labelInline && labelReverse,
            })}
            htmlFor={field.name}
          >
            {label}
            {isOptional && (
              <span className="ml-1 lowercase">({t("forms.optional")})</span>
            )}
            {isReadOnly && showReadOnlyLabel && (
              <span className="ml-1 lowercase">({t("forms.readOnly")})</span>
            )}
            {helperText && (
              <Tooltip content={helperText} placement="top">
                <span className="w-6 h-6 ml-1 -mt-1 text-gray-600">
                  <Icon glyph={Glyphs.infoCircle} />
                </span>
              </Tooltip>
            )}
          </Label>
        )}

        <Component
          innerRef={innerRef}
          id={field.name}
          placeholder={placeholder}
          hasError={hasError}
          className={classNames(componentClassName, {
            "border-2 ": hasError && !withLabelError,
          })}
          field={field}
          form={form}
          required={!isOptional}
          isReadOnly={!!isReadOnly && !isEditableByStaff}
          {...rest}
        />
      </div>
      {hasError && withLabelError && (
        <div className="text-sm text-red-600">
          {getIn(form.errors, field.name)}
        </div>
      )}
    </div>
  );
};

export default withFieldWrapper;
