import i18n from "i18n";
import { Option } from "models/Option";
import { useEffect } from "react";
import { useHistory, withRouter } from "react-router";
import { Tag } from "react-tag-input";

export const formatEnumArray = <T>(data: any[]): (keyof T)[] =>
  data.map(result => result as keyof T);

export const listFormatter = <T>(
  data: any[],
  formatter: (result: any) => T
): T[] => data.map(result => formatter(result));

/**
 * Transforms a list of Resources to a list of string values
 *
 * Example:
 * > const languages = [
 *    { label: 'English (US)', value: 'en_US' },
 *    { label: 'English (UK)', value: 'en_GB' },
 *    { label: 'French', value: 'fr_FR' }
 * ];
 *
 * > parseMultiOptions(resources);
 * > ['en_US', 'en_GB', 'fr_FR']
 *
 * @param {Option[]} value
 * @return {any[]}
 */
export const parseMultiOptions = (value: Option[]): any[] => {
  if (!value) {
    return [];
  }
  return value.map((item: Option) => {
    return item.value;
  });
};

export const parseMultiTags = (value: Tag[]): any[] => {
  if (!value) {
    return [];
  }
  return value.map((item: Tag) => {
    return item.text;
  });
};

export const getTranslatedOptions = (
  options: string[],
  translationKey: string,
  count?: number
): Option[] => {
  return options.map(option => ({
    label: i18n.t(`${translationKey}.${option}`, { count }),
    value: option,
  }));
};

export const validateDate = (value: Date) => {
  let error;
  if (Object.prototype.toString.call(value) !== "[object Date]") {
    error = i18n.t("forms.errors.invalidDate");
  }
  return error;
};

export const scrollIntoView = (ref: React.MutableRefObject<any>) =>
  ref.current.scrollIntoView({ block: "nearest", behavior: "smooth" });

export const isMobile = () => {
  const vendor =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  return !!(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      vendor
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      vendor.substr(0, 4)
    )
  );
};

export const scrollToPositionState = () => {
  const { state }: any = window.history.state;
  if (state) {
    const positionX = state.scrollPositionX ? state.scrollPositionX : 0;
    const positionY = state.scrollPositionY ? state.scrollPositionY : 0;
    setTimeout(() => {
      window.scrollTo(positionX, positionY);
    }, 0);
  }
};

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    if (history.action !== "POP") window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);
  return null;
};
export default withRouter(ScrollToTop);

export const formatEnums = (result: any): string[] =>
  result.enumValues.map((enumValue: any) => enumValue.name);

export const formatTags = (result: (string | null)[]): string[] => {
  const tagList: string[] = [];
  result.forEach(
    (tagValue: string | null) => tagValue != null && tagList.push(tagValue)
  );
  return tagList;
};

export const getErrorKey = (errorMsg: string): string => {
  return errorMsg.replace("GraphQL error:", " ").trim();
};

export const getImage = (name: string) => {
  return require(`assets/images/${name}`);
};

export const getPricePerMeasureUnit = (
  price: number | undefined,
  currency: string,
  measure: number | undefined,
  unit: string | undefined
) => {
  let multiplicator = 1;
  let measureUnit = "KILOGRAM";

  switch (unit) {
    case "CENTILITRE":
      multiplicator = 100;
      break;
    case "MILLILITRE":
    case "GRAM":
      multiplicator = 1000;
      break;
    case "HALF_DOZEN":
      multiplicator = 1 / 6;
      break;
    case "DOZEN":
      multiplicator = 1 / 12;
      break;
  }

  switch (unit) {
    case "LITRE":
    case "MILLILITRE":
    case "CENTILITRE":
      measureUnit = "LITRE";
      break;
    case "UNIT":
    case "DOZEN":
    case "HALF_DOZEN":
    case "CENTIMETER":
      measureUnit = "UNIT";
      break;
  }

  return {
    amount: price && measure ? (price / measure) * multiplicator : 0,
    currency: currency,
    unit: measureUnit,
  };
};

export const getReducePrice = (
  amount: number | undefined,
  isPromoted: boolean,
  isDiscounted: boolean,
  promotedPercent: number | undefined,
  promotedValue: number | undefined,
  discountedPercent: number | undefined
) => {
  let reducePrice = 0;
  if (amount) {
    if (isPromoted && promotedValue && promotedValue > 0) {
      reducePrice = promotedValue;
    } else if (isPromoted && promotedPercent && promotedPercent > 0) {
      reducePrice = amount - (amount / 100) * promotedPercent;
    } else if (isDiscounted && discountedPercent && discountedPercent > 0) {
      reducePrice = amount - (amount / 100) * discountedPercent;
    }
  }
  return reducePrice;
};

export const getFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix = "",
    result: string[] = []
  ) => {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (!value) return;

      let nextKey: string = "";

      if (Array.isArray(obj)) {
        nextKey = prefix ? `${prefix}[${key}]` : key;
      } else {
        nextKey = prefix ? `${prefix}.${key}` : key;
      }

      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};
