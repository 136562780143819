import { gql, useQuery } from "@apollo/client";
import React, { Dispatch, createContext, useContext, useState } from "react";
import { MyPartnerRight } from "types";
import { User } from "./UserContext";

const MY_PARTNER_RIGHT_QUERY = gql`
  query MyPartnerRight {
    myPartnerRight {
      maxNumberItem
      storeDesignMgmt
      storePayment
    }
  }
`;

export interface PartnerRight {
  maxNumberItem: number;
  storeDesignMgmt: boolean;
  storePayment: boolean;
}

export const partnerRightBuilder = (
  values: Partial<PartnerRight> = {}
): PartnerRight => ({
  maxNumberItem: 10,
  storeDesignMgmt: false,
  storePayment: false,
  ...values,
});

interface PartnerRightContextProps {
  partnerRight: PartnerRight;
  setPartnerRight: Dispatch<React.SetStateAction<User | null>>;
}

const PartnerRightContext = createContext<PartnerRightContextProps>({
  partnerRight: partnerRightBuilder(),
  setPartnerRight: () => undefined,
});

const PartnerRightProvider = (props: any) => {
  const [partnerRight, setPartnerRight] = useState<PartnerRight>(
    partnerRightBuilder()
  );

  useQuery<MyPartnerRight>(MY_PARTNER_RIGHT_QUERY, {
    fetchPolicy: "network-only",
    // skip query if `token` key is not found in localStorage
    skip: !localStorage.getItem("token"),
    onCompleted(data) {
      if (data) {
        setPartnerRight({
          maxNumberItem: data.myPartnerRight?.maxNumberItem || 5,
          storeDesignMgmt: data.myPartnerRight?.storeDesignMgmt || false,
          storePayment: data.myPartnerRight?.storePayment || false,
        });
      }
    },
  });

  return (
    <PartnerRightContext.Provider
      value={{ partnerRight, setPartnerRight }}
      {...props}
    />
  );
};

const usePartnerRightContext = () => {
  const context = useContext(PartnerRightContext);
  if (context === undefined) {
    throw new Error(
      `usePartnerRightContext must be used within an PartnerRightProvider`
    );
  }
  return context;
};

export { PartnerRightProvider, usePartnerRightContext };
