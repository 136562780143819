import classNames from "classnames";
import Button from "components/atoms/Button";
import { Glyphs } from "constants/Glyphs";
import React, { ReactNode } from "react";
import ReactModal from "react-modal";

interface ModalProps {
  isOpen: boolean;
  title?: string;
  children?: ReactNode;
  className?: string;
  overlayClassName?: string;
  titleClassName?: string;
  contentLabel?: string;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  withHeader?: boolean;
}

const Modal = ({
  isOpen,
  contentLabel,
  onRequestClose,
  className,
  overlayClassName,
  titleClassName,
  children,
  title,
  withHeader = true,
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={classNames(
        "bg-white w-full m-auto shadow-2xl rounded-lg focus:outline-none xl:mx-10 relative",
        className
      )}
      overlayClassName={classNames(
        "fixed inset-0 bg-white-75 z-50 flex items-center justify-center",
        overlayClassName
      )}
      ariaHideApp={false}
    >
      {withHeader && (
        <div className="flex items-center justify-between p-4 ">
          <div
            className={classNames(
              "ml-12 mr-2 text-2xl font-semibold",
              titleClassName
            )}
          >
            {title}
          </div>
          <Button onClick={onRequestClose} withGlyph={Glyphs.times} />
        </div>
      )}

      {children}
    </ReactModal>
  );
};

export default Modal;
