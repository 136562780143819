import Tippy from "@tippy.js/react";
import React, { ReactElement, ReactNode } from "react";
import { Placement } from "tippy.js";
import "tippy.js/dist/tippy.css";
import { isMobile } from "utils";
import { useWindowWidth } from "utils/customHooks";

interface TooltipProps {
  children: ReactElement<any>;
  content: ReactNode;
  placement?: Placement;
  enabled?: boolean;
  className?: string;
}

const Tooltip = ({
  children,
  content,
  placement = "bottom",
  enabled = true,
  className,
}: TooltipProps) => {
  useWindowWidth();
  return (
    <Tippy
      content={content as any}
      placement={placement}
      enabled={isMobile() ? false : enabled}
      className={"left-0"}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
