import { gql, useApolloClient, useQuery } from "@apollo/client";
import React, { createContext, Dispatch, useContext, useState } from "react";
import { CurrentUser } from "types";
import { getImage } from "utils";
import { User } from "./UserContext";

const PROFILE_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      fullName
      email
      isProducer
      isPickupPoint
      isStaff
      partner {
        phone
        name
      }
    }
  }
`;

interface AuthContextProps {
  currentUser: User | null;
  setCurrentUser: Dispatch<React.SetStateAction<User | null>>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  setCurrentUser: () => undefined,
  logout: () => undefined,
});

const AuthProvider = (props: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { loading } = useQuery<CurrentUser>(PROFILE_QUERY, {
    fetchPolicy: "network-only",
    // skip query if `token` key is not found in localStorage
    skip: !localStorage.getItem("token"),
    onCompleted(data) {
      if (data) {
        setCurrentUser({
          name: data.currentUser?.fullName || "",
          isProducer: data.currentUser?.isProducer || false,
          isPickupPoint: data.currentUser?.isPickupPoint || false,
          isStaff: data.currentUser?.isStaff || false,
          phone: data.currentUser?.partner?.phone || "",
          //TODO: replace image with correct data
          image: getImage("placeholder_thumbnail.jpg"),
          partnerName: data.currentUser?.partner?.name || "",
        });
      }
    },
    onError: () => logout(),
  });
  const client = useApolloClient();

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem("token");
    client.resetStore();
  };

  if (loading) return <>Loading app...</>;

  return (
    <AuthContext.Provider
      value={{ currentUser, setCurrentUser, logout }}
      {...props}
    />
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuthContext };
