import { ApolloProvider } from "@apollo/client";
import client from "client";
import App from "containers/App";
import { AppProviders } from "contexts/AppProviders";
import "i18n";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppProviders>
      <App />
    </AppProviders>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
