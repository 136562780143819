import classNames from "classnames";
import React, { DetailedHTMLProps, LabelHTMLAttributes } from "react";

interface LabelProps
  extends DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {}

const Label = ({ className, ...props }: LabelProps) => {
  return (
    <label
      className={classNames(
        "text-xs font-semibold uppercase tracking-wide text-gray-600",
        className
      )}
      {...props}
    ></label>
  );
};

export default Label;
