import { gql, useLazyQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { MyFarmOrdersStatus } from "types";

const FARM_ORDERS_STATUS = gql`
  query MyFarmOrdersStatus {
    myFarmOrdersStatus {
      nbNewOrders
      nbOrdersToDeliver
      nbOrdersToPrepare
    }
  }
`;

interface OrdersStatusContextProps {
  nbNewOrders: number;
  nbOrdersToDeliver: number;
  nbOrdersToPrepare: number;
  refreshOrdersStatus: () => void;
}

const OrdersStatusContext = createContext<OrdersStatusContextProps>({
  nbNewOrders: 0,
  nbOrdersToDeliver: 0,
  nbOrdersToPrepare: 0,
  refreshOrdersStatus: () => undefined,
});

const OrdersStatusProvider = (props: any) => {
  const [nbNewOrders, setNbNewOrders] = useState<number | undefined>(undefined);
  const [nbOrdersToDeliver, setNbOrdersToDeliver] = useState<
    number | undefined
  >(undefined);
  const [nbOrdersToPrepare, setNbOrdersToPrepare] = useState<
    number | undefined
  >(undefined);

  const [refreshOrdersStatus] = useLazyQuery<MyFarmOrdersStatus>(
    FARM_ORDERS_STATUS,
    {
      fetchPolicy: "network-only",
      onCompleted({ myFarmOrdersStatus }) {
        if (myFarmOrdersStatus) {
          setNbNewOrders(myFarmOrdersStatus.nbNewOrders);
          setNbOrdersToDeliver(myFarmOrdersStatus.nbOrdersToDeliver);
          setNbOrdersToPrepare(myFarmOrdersStatus.nbOrdersToPrepare);
        }
      },
    }
  );

  useEffect(() => {
    if (localStorage.getItem("token")) {
      refreshOrdersStatus();
    }
  }, [refreshOrdersStatus]);

  return (
    <OrdersStatusContext.Provider
      value={{
        nbNewOrders,
        nbOrdersToDeliver,
        nbOrdersToPrepare,
        refreshOrdersStatus,
      }}
      {...props}
    />
  );
};

const useOrdersStatusContext = () => {
  const context = useContext(OrdersStatusContext);
  if (context === undefined) {
    throw new Error("orders status mus be used within a OrdersStatusProvider");
  }
  return context;
};

export { OrdersStatusProvider, useOrdersStatusContext };
