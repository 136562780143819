import React, { FunctionComponent } from "react";
import { AuthProvider } from "./AuthContext";
import { ContactUsProvider } from "./ContactUsContext";
import { ImprovementDisplayerProvider } from "./ImprovementDisplayerContext";
import { NotificationProvider } from "./NotificationContext";
import { OrdersStatusProvider } from "./OrdersStatusContext";
import { PartnerRightProvider } from "./PartnerRightContext";
import { SideBarProvider } from "./SidebarContext";
import { UserProvider } from "./UserContext";

export const AppProviders: FunctionComponent<{}> = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <PartnerRightProvider>
          <OrdersStatusProvider>
            <SideBarProvider>
              <ContactUsProvider>
                <ImprovementDisplayerProvider>
                  <NotificationProvider>{children}</NotificationProvider>
                </ImprovementDisplayerProvider>
              </ContactUsProvider>
            </SideBarProvider>
          </OrdersStatusProvider>
        </PartnerRightProvider>
      </UserProvider>
    </AuthProvider>
  );
};
