import classNames from "classnames";
import React, { FunctionComponent } from "react";
import Tooltip from "../Tooltip";

interface SwitchButtonProps {
  className?: string;
  value?: boolean;
  setValue: () => void;
  disabled?: boolean;
  tooltipValue?: string;
}

const SwitchButton: FunctionComponent<SwitchButtonProps> = ({
  className,
  value = false,
  setValue,
  disabled = false,
  tooltipValue,
}) => {
  return (
    <Tooltip content={tooltipValue} enabled={disabled}>
      <div className={classNames("switch", className)}>
        <input
          type="checkbox"
          checked={value}
          onChange={() => {
            setValue();
          }}
        />
        <div
          className={classNames("slider", { "opacity-25": disabled })}
          onClick={() => !disabled && setValue()}
        />
      </div>
    </Tooltip>
  );
};

export default SwitchButton;
