import classNames from "classnames";
import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
  GlyphPosition,
} from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import SwitchButton from "components/atoms/switchButton";
import Modal from "components/molecules/Modal";
import { Glyphs } from "constants/Glyphs";
import { useNotificationContext } from "contexts/NotificationContext";
import { WebsiteStatus, WebsiteStatusTask } from "models/WebsiteConfiguration";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ToastType } from "react-toastify";
import { useWindowWidth } from "utils/customHooks";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
  websiteStatus: WebsiteStatus | undefined;
  setAvailWebsiteByClient: any;
  refreshWebsiteStatus: () => void;
}

const WebsiteStatusModal: FunctionComponent<Props> = ({
  isOpen,
  setIsOpen,
  websiteStatus,
  setAvailWebsiteByClient,
  refreshWebsiteStatus,
}) => {
  const { t } = useTranslation();

  let history = useHistory();
  const { notify } = useNotificationContext();
  const windowWidth = useWindowWidth();
  const md = windowWidth >= 768;

  const renderTaskLine = (task: WebsiteStatusTask) => {
    return (
      <div className="relative flex flex-col p-1">
        {task.labels.map((label, index) => (
          <div className="flex flex-col ">
            {index > 0 && (
              <div className="ml-10 font-semibold">{t("websiteStatus.or")}</div>
            )}
            <div className="flex flex-row items-center">
              <div className="flex w-full mr-2 font-semibold">
                {t(`${label}`)}
              </div>
              <div className="flex justify-center w-24">
                {task.status ? (
                  <>
                    {index === 0 && (
                      <Icon
                        glyph={Glyphs.check}
                        className={classNames("w-8 mr-4  text-green-600", {
                          // " absolute top-6 right-6": task.labels.length > 1,
                          " absolute ": task.labels.length > 1,
                        })}
                      />
                    )}
                  </>
                ) : (
                  <Button
                    btnStyle={ButtonStyle.contained}
                    btnSize={ButtonSize.small}
                    withGlyph={Glyphs.arrowRight}
                    glyphPosition={GlyphPosition.right}
                    className="h-6 bg-gray-300 rounded-sm"
                    onClick={() => {
                      setIsOpen(false);
                      if (history) {
                        setTimeout(() => history.push(task.routes[index]), 300);
                      }
                    }}
                  >
                    {t("websiteStatus.see")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {isOpen && (
        <Modal
          className="h-full md:h-auto md:m-12 md:max-w-4xl"
          overlayClassName=""
          titleClassName="text-center w-full md:p-4 text-xl text:xl md:text-2xl"
          isOpen={isOpen}
          title={t("websiteStatus.title")}
          onRequestClose={() => setIsOpen(false)}
          withHeader={true}
        >
          <div
            className="flex flex-col p-2 pt-0 overflow-y-auto md:p-8"
            style={{
              maxHeight: md ? "calc(100vh - 8rem)" : "calc(100% - 4rem)",
            }}
          >
            <div
              className={classNames(
                "flex flex-row justify-center p-2 text-lg font-semibold border-b-2 md:text-xl space-x-4",
                {
                  "text-green-600": websiteStatus?.availWebsiteByClient,
                  "text-red-600": !websiteStatus?.availWebsiteByClient,
                }
              )}
            >
              <span>
                {websiteStatus?.availWebsiteByClient
                  ? t("websiteStatus.siteOnline")
                  : t("websiteStatus.siteOffline")}
              </span>
              <SwitchButton
                value={websiteStatus?.availWebsiteByClient}
                setValue={() => {
                  let allMandatoryTaskAreOk = true;
                  websiteStatus?.mandatoryTasks.map(
                    (mandatoryTask: WebsiteStatusTask) => {
                      if (!mandatoryTask.status)
                        allMandatoryTaskAreOk = mandatoryTask.status;
                    }
                  );

                  allMandatoryTaskAreOk || websiteStatus?.availWebsiteByClient
                    ? setAvailWebsiteByClient({
                        variables: {
                          availWebsiteByClient: !websiteStatus?.availWebsiteByClient,
                        },
                      })
                    : notify(t("websiteStatus.onlineError"), {
                        type: ToastType.ERROR,
                      });
                }}
                disabled={
                  !websiteStatus?.availWebsiteByClient &&
                  !websiteStatus?.mandatoryTasksAreTrue
                }
              />
            </div>

            <div className="w-full p-2 border-b-2">
              <span className="flex justify-center text-lg font-semibold">
                {t("websiteStatus.subtitle.mandatory")}
              </span>
              {websiteStatus?.mandatoryTasks.map(mandatoryTask => {
                return renderTaskLine(mandatoryTask);
              })}
            </div>

            <div className="w-full p-2 ">
              <span className="flex justify-center text-lg font-semibold">
                {t("websiteStatus.subtitle.optional")}
              </span>
              {websiteStatus?.optionalTasks.map(optionalTask => {
                return renderTaskLine(optionalTask);
              })}
            </div>
          </div>
        </Modal>
      )}
      <Button
        btnStyle={ButtonStyle.contained}
        btnVariant={
          websiteStatus?.availWebsiteByClient
            ? ButtonVariant.success
            : ButtonVariant.danger
        }
        className={classNames(
          "absolute text-center top-4 lg:top-10 right-4 md:right-6 lg:right-10"
        )}
        onClick={() => {
          refreshWebsiteStatus();
          setIsOpen(true);
        }}
      >
        {websiteStatus?.availWebsiteByClient
          ? t("websiteStatus.button.onLine")
          : t("websiteStatus.button.offLine")}
      </Button>
    </div>
  );
};

export default WebsiteStatusModal;
