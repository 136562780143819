import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import React, { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImage } from "utils";
import { useWindowWidth } from "utils/customHooks";

interface ImprovementDisplayerProps {
  openImprovementDisplayer: (text1?: string, text2?: string) => void;
}

const ImprovementDisplayerContext = React.createContext<
  ImprovementDisplayerProps
>({
  openImprovementDisplayer: (text1, text2) => false,
});

const ImprovementDisplayerProvider: FunctionComponent<{}> = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const windowWith = useWindowWidth();
  const md = windowWith >= 768;
  const [text1, setText1] = useState<string>("");
  const [text2, setText2] = useState<string>("");

  const openImprovementDisplayer = (text1?: string, text2?: string) => {
    text1 && setText1(text1);
    text2 && setText2(text2);

    setOpen(true);
  };

  return (
    <ImprovementDisplayerContext.Provider value={{ openImprovementDisplayer }}>
      {isOpen && (
        <Modal
          className="container flex flex-col max-w-xl max-h-screen-4/5"
          overlayClassName=""
          titleClassName="text-center w-full"
          isOpen={true}
          onRequestClose={() => setOpen(false)}
          withHeader={false}
        >
          <div className="flex flex-col h-full p-6 text-lg">
            <div className="flex flex-col mb-6 text-base font-semibold text-center md:text-lg ">
              {/* <span>{t("improvementDisplay.text1")}</span>
              <span>{t("improvementDisplay.text2")}</span> */}
              <span>{text1}</span>
              <span>{text2}</span>
            </div>
            <div className="flex flex-row mb-4 max-h-[calc(100%-14rem)] space-x-4">
              <div className="">
                <img
                  className="object-contain"
                  src={getImage("offer_1.png")}
                  alt={"offer_1"}
                />
              </div>
              <div className="">
                <img
                  className="object-contain"
                  src={getImage("offer_2.png")}
                  alt={"offer_1"}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <Button
                btnStyle={ButtonStyle.contained}
                btnVariant={ButtonVariant.success}
                btnSize={md ? ButtonSize.medium : ButtonSize.small}
                className="justify-end"
              >
                <a href={"https://www.startboutik.com/buy"} target="blank">
                  {t("improvementDisplay.button")}
                </a>
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {children}
    </ImprovementDisplayerContext.Provider>
  );
};

const useImprovementDisplayerContext = () => {
  const context = useContext(ImprovementDisplayerContext);
  if (context === undefined) {
    throw new Error(
      `useImprovementDisplayerContext must be used within an ImprovementDisplayerProvider`
    );
  }
  return context;
};

export { ImprovementDisplayerProvider, useImprovementDisplayerContext };
