import account from "./account.json";
import breadcrumb from "./breadcrumb.json";
import dashboard from "./dashboard.json";
import errors from "./errors.json";
import forgotPassword from "./forgotPassword.json";
import global from "./global.json";
import inactivityPeriods from "./inactivityPeriods.json";
import loginPage from "./loginPage.json";
import navigation from "./navigation.json";
import openingHours from "./openingHours.json";
import ordersSettings from "./ordersSettings.json";
import productCard from "./productCard.json";
import productsGlobalManagement from "./productsGlobalManagement.json";
import productsManager from "./productsManager.json";
import productsPage from "./productsPage.json";
import productsSettings from "./productsSettings.json";
import resetPassword from "./resetPassword.json";
import settings from "./settings.json";
import socialMedia from "./socialMedia.json";
import storeBasicInfo from "./storeBasicInfo.json";
import storeContactInfo from "./storeContactInfo.json";
import storeOrderProducts from "./storeOrderProducts.json";
import storeOrders from "./storeOrders.json";
import storeOrdersOfTheDay from "./storeOrdersOfTheDay.json";
import storeOrdersToPrepare from "./storeOrdersToPrepare.json";
import stripe from "./stripe.json";
import timeSlots from "./timeSlots.json";
import websiteConfiguration from "./websiteConfiguration.json";
import websiteSettings from "./websiteSettings.json";

export default {
  ...global,
  account,
  breadcrumb,
  dashboard,
  errors,
  storeBasicInfo,
  storeContactInfo,
  storeOrders,
  storeOrderProducts,
  storeOrdersOfTheDay,
  storeOrdersToPrepare,
  forgotPassword,
  inactivityPeriods,
  loginPage,
  navigation,
  openingHours,
  ordersSettings,
  productCard,
  productsManager,
  productsGlobalManagement,
  productsPage,
  productsSettings,
  resetPassword,
  settings,
  socialMedia,
  stripe,
  timeSlots,
  websiteConfiguration,
  websiteSettings,
};
