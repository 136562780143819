import React, { createContext, FunctionComponent, useContext } from "react";
import {
  toast,
  ToastContainer,
  ToastContent,
  ToastOptions,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

interface NotificationContextProps {
  notify: (content: ToastContent, options?: ToastOptions) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  notify: () => undefined,
});

const NotificationProvider: FunctionComponent<{}> = ({ children }) => {
  const notify = (content: ToastContent, options?: ToastOptions) =>
    toast(content, options);

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <ToastContainer hideProgressBar={true} />
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      `useNotificationContext must be used within a NotificationProvider`
    );
  }
  return context;
};

export { NotificationProvider, useNotificationContext };
