import IntroCarousel from "components/organisms/IntroCarousel";
import Cookies from "js-cookie";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useWebsiteStatusContext } from "./WebsiteStatusContext";

interface IntroCarouselProps {
  isOpen: boolean;
  openIntroCarousel: () => void;
}

export const IntroCarouselContext = React.createContext<IntroCarouselProps>({
  isOpen: false,
  openIntroCarousel: () => false,
});

export const IntroCarouselProvider: FunctionComponent<Record<
  string,
  unknown
>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const intro_showed = Cookies.get("intro_showed");
  const isConnected = localStorage.getItem("token");
  let { openWebsiteStatus } = useWebsiteStatusContext();

  useEffect(() => {
    if (!intro_showed && isConnected) {
      setIsOpen(true);
    }
  }, [intro_showed, isConnected]);

  const closeIntroCarousel = () => {
    setIsOpen(false);
    if (!intro_showed) {
      Cookies.set("intro_showed", "true", { expires: 730 });
      openWebsiteStatus();
    }
  };

  const openIntroCarousel = () => {
    setIsOpen(true);
  };

  return (
    <IntroCarouselContext.Provider value={{ isOpen, openIntroCarousel }}>
      {isOpen && (
        <IntroCarousel
          isOpen={isOpen}
          closeIntroCarousel={closeIntroCarousel}
        />
      )}
      {children}
    </IntroCarouselContext.Provider>
  );
};

export const useIntroCarouselContext: () => IntroCarouselProps = () => {
  const context = useContext(IntroCarouselContext);
  if (context === undefined) {
    throw new Error(
      `useIntroCarouselContext must be used within an CookieConsentProvider`
    );
  }
  return context;
};
