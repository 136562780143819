import { fr } from "date-fns/locale";

var formatRelativeLocale: { [key: string]: string } = {
  lastWeek: "eeee 'dernier à' p",
  yesterday: "'hier à' p",
  today: "'aujourd’hui à' p",
  tomorrow: "'demain à' p'",
  nextWeek: "eeee 'prochain à' p",
  other: "P",
};

var formatRelativeLocaleWithoutTime: { [key: string]: string } = {
  lastWeek: "eeee 'dernier",
  yesterday: "'hier",
  today: "'aujourd’hui",
  tomorrow: "'demain",
  nextWeek: "eeee 'prochain",
  other: "P",
};

const customFormatRelative: (...args: any[]) => any = (
  token: string,
  _date: any,
  _baseDate: any,
  _options: any
) => {
  return formatRelativeLocale[token];
};

const customFormatRelativeNoTime: (...args: any[]) => any = (
  token: string,
  _date: any,
  _baseDate: any,
  _options: any
) => {
  return formatRelativeLocaleWithoutTime[token];
};

export const customFrLocale: Locale = {
  ...fr,
  formatRelative: customFormatRelative,
};

export const customFrLocaleNoTime: Locale = {
  ...fr,
  formatRelative: customFormatRelativeNoTime,
};
