import { Glyphs, GlyphSVG } from "constants/Glyphs";
import React, { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  glyph: Glyphs;
}

const Icon = ({ glyph, ...props }: IconProps) => {
  const IconName = GlyphSVG[glyph];
  return <IconName {...props} />;
};

export default Icon;
