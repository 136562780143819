import { gql, useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import Button, {
  ButtonSize,
  ButtonState,
  ButtonStyle,
  ButtonVariant,
  GlyphPosition,
} from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import Select from "components/molecules/forms/Select";
import TextArea from "components/molecules/forms/TextArea";
import { Glyphs } from "constants/Glyphs";
import { useNotificationContext } from "contexts/NotificationContext";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { FunctionComponent, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { ToastType } from "react-toastify";
import { SupportEmailAdd, SupportEmailCategories } from "types";
import { formatEnums, getTranslatedOptions } from "utils";
import { useWindowWidth } from "utils/customHooks";
import { contactUsSchema } from "./validationSchema";

const ADD_SUPPORT_EMAIL = gql`
  mutation SupportEmailAdd($values: SupportEmailInput!) {
    addSupportEmail(input: $values) {
      supportEmail {
        id
      }
    }
  }
`;

const GET_SUPPORT_EMAIL_CATEGORIES = gql`
  query SupportEmailCategories {
    emailCategories: __type(name: "EmailCategory") {
      name
      enumValues {
        name
      }
    }
  }
`;

interface ContactUsProps {
  isOpen: boolean;
}

export const ContactUsContext = React.createContext<ContactUsProps>({
  isOpen: false,
});

interface ContactUsFormValues {
  text: string;
  category: string;
}

const initialValues: ContactUsFormValues = {
  text: "",
  category: "",
};

export const ContactUsProvider: FunctionComponent<Record<string, unknown>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [emailCategories, setEmailCategories] = useState<string[]>([]);
  const { notify } = useNotificationContext();
  const windowWith = useWindowWidth();
  const lg = windowWith >= 1024;

  useQuery<SupportEmailCategories>(GET_SUPPORT_EMAIL_CATEGORIES, {
    fetchPolicy: "cache-and-network",
    onCompleted({ emailCategories }) {
      setEmailCategories(formatEnums(emailCategories));
    },
  });

  const [addSupportEmail] = useMutation<SupportEmailAdd>(ADD_SUPPORT_EMAIL, {
    onCompleted() {
      notify(t("contactUs.form.addSuccess"), {
        type: ToastType.SUCCESS,
      });
      setIsOpen(false);
    },
    onError({ message }) {
      notify(message, { type: ToastType.ERROR });
    },
  });

  const handleSubmit = (
    values: ContactUsFormValues,
    { setSubmitting }: FormikHelpers<ContactUsFormValues>
  ) => {
    addSupportEmail({
      variables: {
        values: { category: values.category, text: values.text },
      },
    });
    setSubmitting(false);
  };

  return (
    <ContactUsContext.Provider value={{ isOpen }}>
      <div>
        {isOpen && (
          <Modal
            className="flex flex-col max-w-md "
            titleClassName="text-center w-full"
            isOpen={true}
            title={t("contactUs.title")}
            onRequestClose={() => setIsOpen(false)}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={contactUsSchema}
            >
              {() => (
                <Form className={classNames("flex flex-col p-8 pt-2")}>
                  <div className="mb-6 text-lg font-semibold">
                    {t("contactUs.intro")}
                  </div>
                  <Field
                    name="category"
                    component={Select}
                    options={getTranslatedOptions(
                      emailCategories,
                      "contactUs.form.category.options"
                    )}
                    label={t("contactUs.form.category.label")}
                    placeholder={t("contactUs.form.category.placeholder")}
                  />
                  <Field
                    name="text"
                    label={t("contactUs.form.text")}
                    className="mb-8"
                    component={TextArea}
                    rows={10}
                  />

                  <Button
                    type="submit"
                    className="self-end"
                    withGlyph={Glyphs.arrowRight}
                    glyphPosition={GlyphPosition.right}
                    btnState={ButtonState.raised}
                    btnStyle={ButtonStyle.contained}
                    btnVariant={ButtonVariant.success}
                  >
                    {t("contactUs.form.send")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal>
        )}
        <Button
          className={classNames(
            "fixed z-10 border-2 border-gray-900 right-5 bottom-5"
          )}
          withGlyph={Glyphs.faq}
          glyphClassName={"text-gray-900 h-10"}
          btnStyle={ButtonStyle.contained}
          btnVariant={ButtonVariant.info}
          btnSize={lg ? ButtonSize.medium : ButtonSize.small}
          onClick={() => setIsOpen(true)}
        />
      </div>

      {children}
    </ContactUsContext.Provider>
  );
};

export const useContactUsContext: () => ContactUsProps = () => {
  const context = useContext(ContactUsContext);
  if (context === undefined) {
    throw new Error(
      `useContactUsContext must be used within an CookieConsentProvider`
    );
  }
  return context;
};
