import { ReactComponent as LogoIcon } from "assets/images/logo_icon.svg";
import Button, {
  ButtonSize,
  ButtonStyle,
  ButtonVariant,
} from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import Modal from "components/molecules/Modal";
import { Glyphs } from "constants/Glyphs";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getImage } from "utils";
import { useWindowWidth } from "utils/customHooks";

interface CarouselObjectType {
  text: string;
  image?: {
    smartphoneURL: string;
    pcURL: string;
  };
}

const carouselObjectList: CarouselObjectType[] = [
  {
    text: "introCarousel.welcome",
  },
  {
    text: "introCarousel.dashboard",
    image: {
      smartphoneURL: "tutoMobile_dashboard.jpg",
      pcURL: "tutoPC_dashboard.jpg",
    },
  },
  {
    text: "introCarousel.products",
    image: {
      smartphoneURL: "tutoMobile_products.jpg",
      pcURL: "tutoPC_products.jpg",
    },
  },
  {
    text: "introCarousel.orders",
    image: {
      smartphoneURL: "tutoMobile_orders.jpg",
      pcURL: "tutoPC_orders.jpg",
    },
  },
  {
    text: "introCarousel.store",
    image: {
      smartphoneURL: "tutoMobile_store.jpg",
      pcURL: "tutoPC_store.jpg",
    },
  },
  {
    text: "introCarousel.site",
    image: {
      smartphoneURL: "tutoMobile_site.jpg",
      pcURL: "tutoPC_site.jpg",
    },
  },
  {
    text: "introCarousel.settings",
    image: {
      smartphoneURL: "tutoMobile_settings.jpg",
      pcURL: "tutoPC_settings.jpg",
    },
  },
  {
    text: "introCarousel.websiteStatus",
    image: {
      smartphoneURL: "tutoMobile_websiteStatus.jpg",
      pcURL: "tutoPC_websiteStatus.jpg",
    },
  },
  // {
  //   text: "introCarousel.intro",
  //   image: {
  //     smartphoneURL: "tutoMobile_intro.jpg",
  //     pcURL: "tutoPC_intro.jpg",
  //   },
  // },
];

interface Props {
  isOpen: boolean;
  closeIntroCarousel: any;
}

const IntroCarousel: FunctionComponent<Props> = ({
  isOpen,
  closeIntroCarousel,
}) => {
  const { t } = useTranslation();
  const windowWith = useWindowWidth();
  const md = windowWith >= 768;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <Modal
      className="h-full md:max-w-5xl md:h-auto "
      overlayClassName=" md:p-0"
      isOpen={isOpen}
      withHeader={false}
    >
      <div className="flex flex-col h-full border-2 border-green-500">
        <div
          className="h-full md:flex"
          style={{ maxHeight: !md ? "calc(100% - 4rem)" : "100%" }}
        >
          {md && currentImageIndex !== 0 && (
            <div className="absolute flex h-full ">
              <Button className="z-10 self-center w-20 h-20 p-0 rounded-full ">
                <Icon
                  className={"h-20 w-20 m-0 text-green-500"}
                  glyph={Glyphs.angleLeft}
                  onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
                />
              </Button>
            </div>
          )}

          <Carousel
            selectedItem={currentImageIndex}
            onChange={setCurrentImageIndex}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            swipeScrollTolerance={100}
            className="w-full h-full bg-black"
            dynamicHeight={false}
          >
            {carouselObjectList.map((carouselObject, index) => {
              return index ? (
                <div
                  key={`introCarousel_${index}`}
                  className="flex flex-col items-center justify-center h-full md:px-20 bg-gray-50"
                >
                  <div className="flex items-center justify-center h-full m-2 md:h-36">
                    <span className="flex text-base whitespace-pre-wrap md:text-xl">
                      {t(carouselObject.text)}
                    </span>
                  </div>

                  <img
                    alt={`introCarouselImage_${index}`}
                    style={
                      !md
                        ? { maxHeight: "calc(100vh - 22rem)" }
                        : { maxHeight: "calc(100vh - 18rem)" }
                    }
                    className="w-auto h-full mb-10 md:w-full md:h-auto"
                    src={
                      md
                        ? carouselObject.image &&
                          getImage(carouselObject.image.pcURL)
                        : carouselObject.image &&
                          getImage(carouselObject.image.smartphoneURL)
                    }
                  />
                </div>
              ) : (
                <div
                  key={`introCarousel_${index}`}
                  className="flex flex-col justify-center h-full bg-gray-200"
                >
                  <LogoIcon className="h-20 mb-6 lg:mb-20 " />
                  <span className="mx-10 text-xl whitespace-pre-wrap md:text-2xl md:mx-24">
                    {t("introCarousel.welcome")}
                  </span>
                </div>
              );
            })}
          </Carousel>
          {md && currentImageIndex !== carouselObjectList.length - 1 && (
            <div className="absolute right-0 flex h-full">
              <Button className="z-10 self-center w-20 h-20 p-0 rounded-full">
                <Icon
                  className={"h-20 w-20 m-0 text-green-500"}
                  glyph={Glyphs.angleRight}
                  onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                />
              </Button>
            </div>
          )}
        </div>

        <Button
          btnVariant={ButtonVariant.success}
          btnSize={md ? ButtonSize.medium : ButtonSize.large}
          btnStyle={ButtonStyle.contained}
          onClick={() => {
            if (currentImageIndex === 7) {
              closeIntroCarousel();
            } else {
              setCurrentImageIndex(currentImageIndex + 1);
            }
          }}
          className="h-16"
        >
          {currentImageIndex === 7
            ? t("introCarousel.closeButton")
            : t("introCarousel.nextButton")}
        </Button>
      </div>
    </Modal>
  );
};

export default IntroCarousel;
